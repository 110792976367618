<template>
  <div class="AddPriority">
    <CModal
      :show.sync="isPriorityCreated"
      :no-close-on-backdrop="true"
      title="Priorité créée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre priorité a bien été créée 👍
      </p>
      <p>
        Vous pouvez maintenant accéder à la page de votre priorité pour y <strong>affecter des collaborateurs.</strong>
      </p>
       
      <template #footer>
        <CButton @click="$router.push('/modify-priority/' + $route.params.id + '/' + createdPriorityId)" color="primary">Accéder à votre priorité</CButton>
        <CButton @click="isPriorityCreated = false" color="dark">Ajouter une nouvelle priorité</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>
    
    <CRow>
      <CCol>
        <h1>Ajout d'une priorité / {{mission.client.name}} - {{mission.name}} </h1>
      </CCol>
    </CRow>
    
    <CCard class="mt-3">
      <CCardBody>
        <CForm v-on:submit.prevent="addPriority()">

          <span> Référent de la priorité : </span>
          <strong v-if="newPriorityReferentObject.id != ''"> {{newPriorityReferentObject.full_name}} </strong>
          <strong v-else> Aucun </strong>

          <vue-bootstrap-typeahead
            class="mt-1"
            v-model="newPriorityReferentName"
            :data="mission.collaborateurs"
            :serializer="item => item.full_name"
            @hit="newPriorityReferentObject = $event"
             placeholder="Chercher votre collaborateur par nom ou prénom"
          />

          <CRow class="mt-3">
            <CCol sm="6">
              <CInput
                label="Nom de la priorité"
                v-model="newPriorityName" type="text"
                maxlength="50"
                placeholder="Nom"
                @input="$v.newPriorityName.$touch()"
                :isValid="$v.newPriorityName.$dirty ? !$v.newPriorityName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 50 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CSelect
                :value.sync="newPriorityState"
                label="Etat prioritaire"
                :options="priorityStateArrayOptions"
                @input="$v.newPriorityState.$touch()"
                :isValid="$v.newPriorityState.$dirty ? !$v.newPriorityState.$error : null"
                custom
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">
              <div v-if="$browserDetect.isSafari">
                <b-form-group id="create-priority-date_echeance-group" label="Date d'échéance de votre priorité" label-for="create-priority-date_echeance-input">
                  <b-form-datepicker
                    id="create-priority-date_echeance-input"
                    name="create-priority-date_echeance-input"
                    locale="fr"
                    placeholder="Choisissez une date"
                    v-model="newPriorityEndingDate" class="mb-2"
                    @blur="dateEcheanceInputOnBlur"
                    @input="$v.newPriorityEndingDate.$touch()"
                    :state="$v.newPriorityEndingDate.$dirty ? !$v.newPriorityEndingDate.$error : null">
                  </b-form-datepicker>
                    <small class="text-danger" v-if="!$v.newPriorityEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </b-form-group>
              </div>
              <div v-else>
                <CInput
                  label="Date d'échéance de votre priorité"
                  v-model="newPriorityEndingDate" type="date"
                  placeholder="AAAA-MM-DD"
                  @blur="dateEcheanceInputOnBlur"
                  @input="$v.newPriorityEndingDate.$touch()"
                  :isValid="$v.newPriorityEndingDate.$dirty ? !$v.newPriorityEndingDate.$error : null"
                >
                <template #description>
                  <div v-if="$browserDetect.isIE" class="text-info">
                    <small>Vous utilisez Internet Explorer, veuillez entrer votre
                    date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                  </div>
                  <small class="text-danger" v-if="!$v.newPriorityEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </template>
                </CInput>
              </div>

            </CCol>
            <CCol sm="6">

              <div v-if="$browserDetect.isSafari">
                <b-form-group id="create-priority-internal_ending_date-group" label="Deadline interne de votre priorité" label-for="create-priority-internal_ending_date-input">
                  <b-form-datepicker
                    id="create-priority-internal_ending_date-input"
                    name="create-priority-internal_ending_date-input"
                    locale="fr"
                    placeholder="Choisissez une date"
                    v-model="newPriorityInternalEndingDate" class="mb-2"
                    @input="$v.newPriorityInternalEndingDate.$touch()"
                    :state="$v.newPriorityInternalEndingDate.$dirty ? !$v.newPriorityInternalEndingDate.$error : null">
                  </b-form-datepicker>
                  <small class="text-danger" v-if="!$v.newPriorityInternalEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </b-form-group>
              </div>
              <div v-else>
                <CInput
                  label="Deadline interne de votre priorité"
                  v-model="newPriorityInternalEndingDate" type="date"
                  placeholder="AAAA-MM-DD"
                  @input="$v.newPriorityInternalEndingDate.$touch()"
                  :isValid="$v.newPriorityInternalEndingDate.$dirty ? !$v.newPriorityInternalEndingDate.$error : null"
                >
                <template #description>
                  <div v-if="$browserDetect.isIE" class="text-info">
                    <small>Vous utilisez Internet Explorer, veuillez entrer votre
                    date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                  </div>
                  <small class="text-danger" v-if="!$v.newPriorityInternalEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </template>
                </CInput>
              </div>
            </CCol>
          </CRow>


          <CTextarea
            label="Décrivez brièvement le contenu de la priorité"
            v-model="newPriorityDescription" type="text"
            rows="3"
            maxlength="300"
            placeholder="Description"
            @input="$v.newPriorityDescription.$touch()"
            :isValid="$v.newPriorityDescription.$dirty ? !$v.newPriorityDescription.$error : null"
          >
          </CTextarea>

          <CRow class="mt-4">
            <CCol class="text-center">
              <CButton
                type="submit" :color="addPriorityButtonStyle"
                shape="pill" block class="px-4" size="lg"
                :disabled="$v.newPriorityReferentObject.$invalid || $v.newPriorityName.$invalid || $v.newPriorityState.$invalid || $v.newPriorityInternalEndingDate.$invalid || $v.newPriorityEndingDate.$invalid || $v.newPriorityDescription.$invalid || addPriorityInProcess">
                <CSpinner size="sm" label="create priority spinner" v-if="addPriorityInProcess"></CSpinner>
                {{ addPriorityButtonText }}
              </CButton>
            </CCol>
          </CRow>

        </CForm>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'
import { APIUserConnected } from '@/api/APIUserConnected'
import { isDate } from '@/validators/validators'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()


export default {
  name: 'AddPriority',
  components: {
    Loading,
    VueBootstrapTypeahead
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      mission: {
        id: 0,
        name: '',
        client: '',
        type: '',
        state: '',
        montant_ht: '',
        starting_date: '',
        ending_date: '',
        description: '',
        is_active: '',
        created_at: '',

        contact: {
          id: 0,
          first_name: '',
          last_name: '',
          full_name: '',
        },
        referent: {
          id: 0,
          full_name: '',
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        collaborateurs: []
      },
      isMissionLoading: false,

      createdPriorityId: '',
      isPriorityCreated: false,
      newPriorityName: '',
      newPriorityState: 'MAJ',
      newPriorityInternalEndingDate: '',
      newPriorityEndingDate: '',
      newPriorityDescription: '',

      newPriorityReferentName: '',
      newPriorityReferentObject: {
        id: '',
        full_name: ''
      },

      priorityStateArrayOptions: [
        { label: 'Priorité majeure', value: 'MAJ' },
        { label: 'Priorité mineure', value: 'MIN' },
      ],

      addPriorityButtonText: 'Ajouter une nouvelle priorité',
      addPriorityButtonStyle: 'outline-primary',
      addPriorityInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isMissionLoading) {
        return true
      }
      return false
    }
  },
  validations: {
    newPriorityReferentObject: {
      id: {
        required
      }
    },
    newPriorityName: {
      minLength: minLength(2),
      maxLength: maxLength(50),
      required
    },
    newPriorityState: {
      required,
    },
    newPriorityInternalEndingDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newPriorityEndingDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newPriorityDescription: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(300)
    },
  },

  created: function() {
    this.getMission()
  },

  watch: {
    newPriorityReferentName(newQuery) {
      if (newQuery == '') {
        this.newPriorityReferentObject = {
          id: '',
          full_name: ''
        }
      }
    },
  },

  methods: {
    // ------------- Getters --------------
    getMission () {
      this.isMissionLoading = true
      apiUserConnected.getMission(this.token, this.$route.params.id)
      .then((result) => {
        this.mission = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionLoading = false
      })
    },

    // ----------- ADD PRIORITY ---------
    addPriority () {
      this.addPriorityInProcess = true
      this.addPriorityButtonText = "Création en cours"
      this.addPriorityButtonStyle = 'secondary'
      apiUserConnected.addPriority(this.token,
        this.$route.params.id,
        this.newPriorityReferentObject.id, this.newPriorityName,
        this.newPriorityState, this.newPriorityInternalEndingDate,
        this.newPriorityEndingDate, this.newPriorityDescription)
      .then((result) => {
        this.isPriorityCreated = true
        this.createdPriorityId = result.data.id
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addPriorityInProcess = false
        this.addPriorityButtonText = "Ajouter une nouvelle priorité"
        this.addPriorityButtonStyle = 'outline-primary'
      })
    },

    // ---------- BASIC FUNCTIONS ----------
    dateEcheanceInputOnBlur () {
      if (!this.$v.newPriorityEndingDate.$invalid && this.newPriorityInternalEndingDate == '') {
        this.newPriorityInternalEndingDate = this.newPriorityEndingDate
      }
    }
  }
}
</script>

<style>
.autocomplete__icon {
    height: unset;
    width: 14px;
}
</style>
